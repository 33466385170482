import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	private history: string[] = [];

	constructor(
		private router: Router,
		private location: Location
	) {}

	/**
	 * Initializes the navigation service by subscribing to router events
	 * and pushing the navigation URLs to the history array.
	 */
	initNavigationService(): void {
		this.router.events.subscribe((event) => {
			// Subscribe to router events
			if (event instanceof NavigationEnd) {
				// Check if the event is NavigationEnd
				this.history.push(event.urlAfterRedirects);
				// Add the URL to history
			}
		});
	}

	/**
	 * Navigates back to the previous page in the history. If the history is empty,
	 * tries to navigate to a relative path. If that also fails, navigates to the root URL.
	 */
	back(): void {
		// Remove the last item from history
		this.history.pop();

		// If history is not empty
		if (this.history.length > 0) {
			// Navigate back using Location service
			this.location.back();
		} else {
			// If history is empty
			const canNavigateBack = this.tryNavigateToRelativePath();
			// Try to navigate to a relative path
			if (!canNavigateBack) {
				// If navigation to relative path fails
				this.router.navigateByUrl('/');
				// Navigate to the root URL
			}
		}
	}

	/**
	 * Tries to navigate to a relative path (../../) from the current path.
	 * Returns true if navigation was successful, false otherwise.
	 *
	 * @returns {boolean} - Indicates whether navigation to relative path was successful.
	 */
	private tryNavigateToRelativePath(): boolean {
		// Get the current path
		const currentPath = this.location.path();
		// Split the path into segments and filter out empty segments
		const segments = currentPath.split('/').filter((segment) => segment.length > 0);

		// If there are more than one segment
		if (segments.length > 1) {
			// Navigate to the parent route
			this.router.navigate(['../../']);
			// Indicate that navigation was successful
			return true;
		}

		// Indicate that navigation was not possible
		return false;
	}
}
